import $ from "jquery";
import userAgentCheck from "./modules/_userAgentChecker";
import { goToPageTop, smoothScroll } from "./modules/_scrolls";
import mq from "./modules/_checkDevice";
import sal from "sal.js";
import Swiper, { Autoplay, EffectFade, Navigation, Pagination } from 'swiper';

import { AmbientLight, DirectionalLight, Scene } from "three";
import { GLTFLoader } from "three/examples/jsm/loaders/GLTFLoader";
import ShuffleText from "shuffle-text";
import Vivus from "vivus";
import ViewportExtra from "viewport-extra";

Swiper.use([Navigation, Pagination, Autoplay, EffectFade]);


if (typeof IntersectionObserver === "undefined") {
  // eslint-disable-next-line global-require
  require("intersection-observer");
}

// UA Check Module
userAgentCheck();

let map;
const mapOptions = {
  tilt: 67.5,
  heading: 0,
  zoom: 19,
  center: { lat: 34.998957, lng: 135.759305 },
  mapId: "15431d2b469f209e",
  // disable interactions due to animation loop and moveCamera
};


// Menu UI Functions
const menuToggleFunc = () => {
  const mTrigger = document.getElementById("menu-trigger");
  const jsSamuraiMenuChildren = document.querySelectorAll(".js-samurai-menu");
  // console.log(jsSamuraiMenuChildren);
  // jsSamuraiMenuChildrenに対してクリックイベント実行
  // jsSamuraiMenuChildrenが存在する場合のみ処理を実行
  if (jsSamuraiMenuChildren.length > 0) {
    jsSamuraiMenuChildren.forEach((child) => {
      child.addEventListener("click", () => {
        if (body.classList.contains("menu-show")) {
          body.classList.remove("menu-show");
        }
      });
    });
  }

  // メニュートリガーに対してクリックイベント実行
  mTrigger.addEventListener("click", () => {
    if ($("body").hasClass("menu-show")) {
      $("body").removeClass("menu-show");
    } else {
      $("body").addClass("menu-show");
    }
  });

};

// sekou slide
const sekouSlideShow = () => {
  const galleryThumbs = new Swiper(".gallery-thumbs", {
    slidesPerView: 6,
    direction: "vertical",
    freeMode: true,
    watchSlidesVisibility: true,
    watchSlidesProgress: true,
  });

  const galleryTop = new Swiper(".gallery-top", {
    navigation: {
      nextEl: ".swiper-button-next",
      prevEl: ".swiper-button-prev",
    },
    thumbs: {
      swiper: galleryThumbs,
    },
  });
};

const newsTopSwipe = () => {
  const newsTopSwipe = new Swiper(".news-swiper", {
    // effect: 'fade',
    speed: 1000,
    slidePerView: "1", //スライド枚数
    loop: true, //ループを有効にする
    autoplay: {
      delay: 5000,
    }
  });
};

const mediaTopSwipe = () => {
  const mediaTopSwipe = new Swiper(".media-swiper", {
    loop: true,
    slidesPerView: 3,
    speed: 1500,
    // centeredSlides: true,
    // autoplay: {
    //   delay: 3000,
    // },
    navigation: {
      nextEl: '.swiper-button-next-media',
      prevEl: '.swiper-button-prev-media',
    },
  });
};


const samuraiFirstSwipe = () => {
  if ($(".samurai-header").length > 0) {
    const samuraiFirstSlider = document.getElementById("samuraiFirstSlider");
    samuraiFirstSlider.classList.add("show");
    setTimeout(() => {
      const newsTopSwipe = new Swiper(".samurai-first-swiper", {
        effect: "fade",
        speed: 3000,
        autoplay: {
          delay: 3000,
        },
        loop: true,
      });
    }, 200);

  }

};

const avilityTopSwipe = () => {
  const avilityTopSwipe = new Swiper(".ability-swiper", {
    loop: true,
    slidesPerView: 3,
    speed: 1500,
    // centeredSlides: true,
    // autoplay: {
    //   delay: 5000,
    // },
    navigation: {
      nextEl: '.swiper-button-next-av',
      prevEl: '.swiper-button-prev-av',
    },
  });
};



const firstTopSwipe = () => {
  const firstTopSwipe = new Swiper(".top-first-swiper", {
    loop: true,
    speed: 3000,
    centeredSlides: true,
    autoplay: {
      delay: 5000,
    },
    pagination: {
      el: ".swiper-pagination",
      type: "bullets",
      clickable: true,
    },
    // navigation: {
    //   nextEl: '.swiper-button-next',
    //   prevEl: '.swiper-button-prev',
    // },
  });
};


const memberSwipe = () => {
  const mediaTopSwipe1 = new Swiper(".member-swiper-1", {
    loop: true,
    effect: 'fade',
    slidesPerView: 1,
    speed: 1500,
    centeredSlides: true,
    autoplay: {
      delay: 3000,
    }
  });

  const mediaTopSwipe2 = new Swiper(".member-swiper-2", {
    loop: true,
    effect: 'fade',
    slidesPerView: 1,
    speed: 1500,
    centeredSlides: true,
    autoplay: {
      delay: 3000,
    }
  });

  const mediaTopSwipe3 = new Swiper(".member-swiper-3", {
    loop: true,
    effect: 'fade',
    slidesPerView: 1,
    speed: 1500,
    centeredSlides: true,
    autoplay: {
      delay: 3000,
    }
  });

  const mediaTopSwipe4 = new Swiper(".member-swiper-4", {
    loop: true,
    effect: 'fade',
    slidesPerView: 1,
    speed: 1500,
    centeredSlides: true,
    autoplay: {
      delay: 3000,
    }
  });
};

const formCheck = () => {
  //エラーを表示する関数（error クラスの p 要素を追加して表示）
  function show_error(message, this$) {
    var text = this$.parent().find("label").text() + message;
    this$.parent().append("<p class='error'>" + text + "</p>");
  }

  //フォームが送信される際のイベントハンドラの設定
  $("#formPage1").submit(function () {
    //エラー表示の初期化
    $("p.error").remove();
    $("div").removeClass("error");
    var text = "";
    $("#errorDispaly").remove();

    //メールアドレスの検証
    var email = $.trim($("#email").val());
    if (
      email &&
      !/^([a-z0-9\+_\-]+)(\.[a-z0-9\+_\-]+)*@([a-z0-9\-]+\.)+[a-z]{2,6}$/gi.test(
        email
      )
    ) {
      $("#email").after(
        "<p class='error'>メールアドレスの形式が異なります</p>"
      );
    }
    //確認用メールアドレスの検証
    // var email_check =  $.trim($("#email_check").val());
    // if(email_check && email_check != $.trim($("input[name="+$("#email_check").attr("name").replace(/^(.+)_check$/, "$1")+"]").val())){
    //   show_error("が異なります", $("#email_check"));
    // }

    //電話番号の検証
    var tel = $.trim($("#tel").val());
    if (
      tel &&
      !/^\(?\d{2,5}\)?[-(\.\s]{0,2}\d{1,4}[-)\.\s]{0,2}\d{3,4}$/gi.test(tel)
    ) {
      $("#tel").after(
        "<p class='error'>電話番号の形式が異なります（半角英数字でご入力ください）</p>"
      );
    }

    //カナの検証
    var kana = $.trim($("#kana").val());
    if (kana && !kana.match(/^[ァ-ヶー　]+$/)) {
      $("#kana").after(
        "<p class='error'>全角カタカナではない文字が含まれています</p>"
      );
    }

    //1行テキスト入力フォームとテキストエリアの検証
    $(":text,textarea")
      .filter(".validate")
      .each(function () {
        //必須項目の検証
        $(this)
          .filter(".required")
          .each(function () {
            if ($(this).val() == "") {
              show_error("必須項目です", $(this));
            }
          });
        //文字数の検証
        $(this)
          .filter(".max30")
          .each(function () {
            if ($(this).val().length > 30) {
              show_error(" は30文字以内です", $(this));
            }
          });
        $(this)
          .filter(".max50")
          .each(function () {
            if ($(this).val().length > 50) {
              show_error(" は50文字以内です", $(this));
            }
          });
        $(this)
          .filter(".max100")
          .each(function () {
            if ($(this).val().length > 100) {
              show_error(" は100文字以内です", $(this));
            }
          });
        $(this)
          .filter(".max1000")
          .each(function () {
            if ($(this).val().length > 1000) {
              show_error(" は1000文字以内でお願いします", $(this));
            }
          });
      });

    //error クラスの追加の処理
    if ($("p.error").length > 0) {
      $("p.error").parent().addClass("error");
      $("html,body").animate(
        { scrollTop: $("p.error:first").offset().top - 180 },
        "slow"
      );
      return false;
    }
  });

  //テキストエリアに入力された文字数を表示
  // $("textarea").on('keydown keyup change', function() {
  //   var count = $(this).val().length;
  //   $("#count").text(count);
  //   if(count > 1000) {
  //     $("#count").css({color: 'red', fontWeight: 'bold'});
  //   }else{
  //     $("#count").css({color: '#333', fontWeight: 'normal'});
  //   }
  // });
};

// iPad 対応
const iPadViewPort = () => {
  console.log("iPadViewPort");
    // タブレット端末かどうかを判定
    var ua = navigator.userAgent
    var isSmartPhone = ua.indexOf('iPhone') > -1 ||
        (ua.indexOf('Android') > -1 && ua.indexOf('Mobile') > -1)
    var isTablet = !isSmartPhone && (
        ua.indexOf('iPad') > -1 ||
        (ua.indexOf('Macintosh') > -1 && 'ontouchend' in document) ||
        ua.indexOf('Android') > -1
    )
    // タブレット端末でのみ最小幅を設定
    if (isTablet) ViewportExtra.setContent({ minWidth: 1024 })

}


// All Functions Run
window.addEventListener("DOMContentLoaded", () => {
  iPadViewPort();
  sal();
  // goToPageTop(".js-page-top");
  // smoothScroll(".js-scroll");
  menuToggleFunc();
  if ($("#topPage").length) {
    newsTopSwipe();
    mediaTopSwipe();
    firstTopSwipe();
    avilityTopSwipe();
  }

  if ($("#companyMap").length) {
    // initMap();
  }
  if ($("#formPage1").length) {
    formCheck();
  }
  if ($("#memberPage").length) {
    memberSwipe();
  }
  samuraiFirstSwipe();
});

// SCROLL AND TOCH START ANIMATION
var title01StartFlg = false;
var title02StartFlg = false;
var title03StartFlg = false;
var title04StartFlg = false;
var imgAnime01Flg = false;
var imgAnime02Flg = false;
var imgAnime03Flg = false;
var imgAnime04Flg = false;

$(window).on("touchstart scroll", function () {
  var scrollTop = document.documentElement.scrollTop || document.body.scrollTop;
    if(scrollTop > 200){

      if ($(".samurai-header").length > 0) {
        $(".samurai-header").addClass("header-fixed");
      }else{
        $(".header").addClass("header-fixed");
      }

    }else{

      if ($(".samurai-header").length > 0) {
        $(".samurai-header").removeClass("header-fixed");
      }else{
        $(".header").removeClass("header-fixed");
      }

  }

  if ($("#dronePage").length) {

    var windowHeight = document.documentElement.clientHeight;

    var element01 = document.getElementById("jsShuffleText01");
    var rectTitle01 = element01.getBoundingClientRect();
    var titlePos01 = rectTitle01.top + scrollTop + 200;

    var element02 = document.getElementById("jsShuffleText02");
    var rectTitle02 = element02.getBoundingClientRect();
    var titlePos02 = rectTitle02.top + scrollTop + 200;

    var element03 = document.getElementById("jsShuffleText03");
    var rectTitle03 = element03.getBoundingClientRect();
    var titlePos03 = rectTitle03.top + scrollTop + 200;

    var element04 = document.getElementById("jsShuffleText04");
    var rectTitle04 = element04.getBoundingClientRect();
    var titlePos04 = rectTitle04.top + scrollTop + 200;

    var animeElement01 = document.getElementById("imgAnime01");
    var animeElement01Img = "/img/animatesvg/m210_animated.svg";
    var animeElementSizse01 = animeElement01.getBoundingClientRect();
    var animeElementPos01 = animeElementSizse01.top + scrollTop + 200;

    var animeElement02 = document.getElementById("imgAnime02");
    var animeElement02Img = "/img/animatesvg/camera01_animated.svg";
    var animeElementSizse02 = animeElement02.getBoundingClientRect();
    var animeElementPos02 = animeElementSizse02.top + scrollTop + 200;

    var animeElement03 = document.getElementById("imgAnime03");
    var animeElement03Img = "/img/animatesvg/puropo_animated.svg";
    var animeElementSizse03 = animeElement03.getBoundingClientRect();
    var animeElementPos03 = animeElementSizse03.top + scrollTop + 200;

    var animeElement04 = document.getElementById("imgAnime04");
    var animeElement04Img = "/img/animatesvg/zoomcamera_animated.svg";
    var animeElementSizse04 = animeElement04.getBoundingClientRect();
    var animeElementPos04 = animeElementSizse04.top + scrollTop + 200;

    if (scrollTop >= titlePos01 - windowHeight) {
      if (!title01StartFlg) {
        title01StartFlg = true;
        var titleShuffle01 = new ShuffleText(element01);
        titleShuffle01.duration = 1300;
        titleShuffle01.start();
      }
    }

    if (scrollTop >= titlePos02 - windowHeight) {
      if (!title02StartFlg) {
        title02StartFlg = true;
        var titleShuffle02 = new ShuffleText(element02);
        titleShuffle02.duration = 1300;
        titleShuffle02.start();
      }
    }

    if (scrollTop >= titlePos03 - windowHeight) {
      if (!title03StartFlg) {
        title03StartFlg = true;
        var titleShuffle03 = new ShuffleText(element03);
        titleShuffle03.duration = 1300;
        titleShuffle03.start();
      }
    }

    if (scrollTop >= titlePos04 - windowHeight) {
      if (!title04StartFlg) {
        title04StartFlg = true;
        var titleShuffle04 = new ShuffleText(element04);
        titleShuffle04.duration = 1300;
        titleShuffle04.start();
      }
    }

    if (scrollTop >= animeElementPos01 - windowHeight) {
      if (!imgAnime01Flg) {
        imgAnime01Flg = true;
        animeElement01.src = "";
        var _img = new Image();
        _img.src = animeElement01Img;
        setTimeout(function () {
          animeElement01.src = animeElement01Img;
        }, 1000);
      }
    }

    if (scrollTop >= animeElementPos02 - windowHeight) {
      if (!imgAnime02Flg) {
        imgAnime02Flg = true;
        animeElement02.src = "";
        var _img = new Image();
        _img.src = animeElement02Img;
        setTimeout(function () {
          animeElement02.src = animeElement02Img;
        }, 1000);
      }
    }

    if (scrollTop >= animeElementPos03 - windowHeight) {
      if (!imgAnime03Flg) {
        imgAnime03Flg = true;
        animeElement03.src = "";
        var _img = new Image();
        _img.src = animeElement03Img;
        setTimeout(function () {
          animeElement03.src = animeElement03Img;
        }, 1000);
      }
    }

    if (scrollTop >= animeElementPos04 - windowHeight) {
      if (!imgAnime04Flg) {
        imgAnime04Flg = true;
        animeElement04.src = "";
        var _img = new Image();
        _img.src = animeElement04Img;
        setTimeout(function () {
          animeElement04.src = animeElement04Img;
        }, 1000);
      }
    }
  }

});
